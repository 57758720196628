import React, { useEffect, useRef, useState } from 'react';
// import { FormspreeProvider } from '@formspree/react';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import BackToTop from 'components/UI/BackToTop';
import Container from 'components/Container';
import GenericHead from 'components/GenericHead';
import TextContent from 'components/TextContent';
import ContactForm from 'components/ContactForm';
import { GenericWrapper, GenericContent } from './styles';

// const FORMSPREE_PROJECT_ID = '1775121614745108207';

const Generic = (props) => {
  const { title, asset, body, excerpt } = props?.data?.contentfulGenericPage;
  const formTitle = props?.data?.contentfulGenericPage?.modules?.[0]?.title;
  const formExerpt = props?.data?.contentfulGenericPage?.modules?.[0]?.exerpt;
  const toTopName = 'Retour en haut de page';

  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);
  const height = useWindowSizes().windowHeight;

  useEffect(() => {
    const contentHeight = contentRef?.current.clientHeight;
    contentHeight > height ? setShowButton(true) : setShowButton(false);
  }, [contentRef, height]);

  console.log(asset)
  console.log(excerpt)
  console.log(title)

  return (
    <GenericWrapper>
      <Container
        topSpacingDesktop="214"
        topSpacingTablet="214"
        bottomSpacingMobile="60"
        bottomSpacingTablet="90"
        bottomSpacingDesktop="90"
        spacedTablet={true}
      >
        <GenericContent ref={contentRef}>
          <GenericHead title={title} headBody={excerpt} image={asset} />
          <TextContent
            style={{ textAlign: 'center' }}
            body={body}
            capMobile="20"
            capTablet="584"
            capDesktop="900"
          />
        </GenericContent>
        <ContactForm title={formTitle} exerpt={formExerpt}/>
        {showButton && <BackToTop name={toTopName} />}
      </Container>
    </GenericWrapper>
  );
};

export default Generic;
