import React from 'react';
import { graphql } from 'gatsby';
import Generic from 'components/Layouts/Generic';

const GenericTemplate = (props) => <Generic {...props} />;

export default GenericTemplate;

export const genericQuery = graphql`
  query ($handle: String!) {
    contentfulGenericPage(handle: { eq: $handle }) {
      handle
      contentful_id
      id
      title
      excerpt
      asset {
        gatsbyImageData(aspectRatio: 2.16)
        title
        file {
          url
        }
      }
      body {
        raw
      }
      modules {
        title
        excerpt
        contentful_id
      }
    }
  }
`;
