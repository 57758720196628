import React, {useEffect} from "react";
import { useForm } from '@formspree/react';
import {
  StyledContactForm,
  TextContainer,
  Title,
  Body,
  Form,
  PersonalInfo,
  Button,
  Label,
  Input,
  Textarea,
  ErrorText,
} from './styles';

const ContactForm = ({title, exerpt}) => {
  const [state, handleSubmit] = useForm('xbjqwerb');

  const isEmpty = state.errors.some(el => el.code === 'EMPTY');
  const isNameValid = !isEmpty && !state.errors.some(el => el.field === 'name');
  const isSurnameValid = !isEmpty && !state.errors.some(el => el.field === 'surname');
  const isEmailValid = !isEmpty && !state.errors.some(el => el.field === 'email');
  const isMessageValid = !isEmpty && !state.errors.some(el => el.field === 'message');

  useEffect(() => {
    if(state.succeeded) {
      const form = document.getElementById('contactForm');
      setTimeout(() => {
        form.reset();
      }, 1000)
    }
  }, [state.succeeded])

  const nameLabelText = 'Nom*';
  const surnameLabelText = 'Prénom*';
  const emailLabelText = 'Courriel*';
  const messageLabelText = 'Message*';
  const errorNameText = `S'il vous plaît, inscrire votre nom`;
  const errorSurnameText = `S'il vous plaît, inscrire votre prénom`;
  const errorEmailText = `S'il vous plaît, inscrire votre courriel`;
  const errorMessageText = `S'il vous plaît, entrer un message`;

  return (
    <StyledContactForm>
      <TextContainer>
        {title && <Title>Contactez-nous</Title>}
        {exerpt && <Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas eu diam vel morbi curabitur fermentum, euismod. Cursus at aenean facilisis lacus, urna at. In a euismod fames massa quis</Body>}
      </TextContainer>

      <Form onSubmit={handleSubmit} id="contactForm" noValidate>
        <PersonalInfo>
          <Label className={isNameValid ? '' : 'error'} htmlFor="name"> {nameLabelText}
            <Input className="Input"
              id="name"
              name="name"
              type="text"
              required
            />
            <ErrorText className="ErrorText">{errorNameText}</ErrorText>
          </Label>

          <Label className={isSurnameValid ? '' : 'error'} htmlFor="surname"> {surnameLabelText}
            <Input
              className="Input"
              id="surname"
              name="surname"
              type="text"
              required
            />
            <ErrorText className="ErrorText">{errorSurnameText}</ErrorText>
          </Label>
        </PersonalInfo>

        <Label className={isEmailValid ? '' : 'error'} htmlFor="email"> {emailLabelText}
          <Input
            className="Input"
            id="email"
            name="email"
            type="email"
            required
          />
          <ErrorText className="ErrorText">{errorEmailText}</ErrorText>
        </Label>

        <Label className={isMessageValid ? '' : 'error'} htmlFor="message"> {messageLabelText}
          <Textarea
            className="Input"
            id="message"
            name="message"
            type="text"
            required
          />
          <ErrorText className="ErrorText">{errorMessageText}</ErrorText>
        </Label>

        <Button
          className={(state.errors.length > 0 ? 'error' : '') + ' ' + (state.succeeded ? 'success' : '') }
          type="submit"
          disabled={state.submitting}
        >
          {state.succeeded ? 'Succès' : 'Envoyer'}
        </Button>
      </Form>
    </StyledContactForm>
  )
}

export default ContactForm ;